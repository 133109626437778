import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MessageService } from 'src/app/services/message/message.service';
import { environment } from 'src/environments/environment';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogVideo } from 'src/app/pages/home/home.component';
import { Subscription } from 'rxjs';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { Router } from '@angular/router';
import { DashboardDialogComponent, InvitationDialogComponent } from '../dashboard-menu/dashboard-menu.component';
/** @title Responsive sidenav */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('hamburguerX', [
            state('hamburguer', style({})),
            state('topX', style({
                transform: 'rotate(45deg)',
                transformOrigin: 'left',
                margin: '6px',
                position: 'relative',
                top: '-5px'
            })),
            state('hide', style({
                opacity: 0
            })),
            state('bottomX', style({
                transform: 'rotate(-45deg)',
                transformOrigin: 'left',
                margin: '6px',
                width: '30px'
            })),
            transition('* => *', [
                animate('0.2s')
            ]),
        ]),
    ],
})
export class HeaderComponent implements OnInit {
    hideNav = Number(localStorage.getItem('hideNav'));
    isHamburguer = true;
    userData : any;
    currentUser: any;
    lang = 'fr';
    isOpenMenu = false;
    loggedIn = this.authService.logIn;
    startLoad = true;
    name: string;
    profile: string;
    commonRef: string;
    inviteBadge = 0;
    userManager = false;
    userEmail = '';
    constructor(private translate: TranslateService,
        private messageService: MessageService,
        public authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private coreService: CoreServiceService) {
    }

    ngOnInit() {
        this.hideNav = localStorage.getItem('hideNav') && localStorage.getItem('hideNav') !== "0" ? 1 : 0;
        this.isHamburguer = this.hideNav == 0 ? true : false;
        this.authService.getUserInvitation(0, 10).subscribe(res => {
          this.inviteBadge = res['hydra:totalItems'];
        });
        this.authService.obInviteBadge.subscribe((res) => {
          console.log("invv "+res['hydra:totalItems'])
            this.inviteBadge = res['hydra:totalItems'];
          });
          this.authService.obreceiveInvitation.subscribe((idMessage) =>{ 
            console.log("Iinvitation meme compte ")
            if(this.inviteBadge > 0) this.inviteBadge = this.inviteBadge -  1;
          });
        this.authService.endLoading.subscribe((term) =>{ 
            if(term == 1){
                setTimeout(()=>{
                    this.startLoad = false;
                }, 500);
            }
          });
        this.messageService.getTranslate()
            .subscribe(
                (data) => {
                    this.lang = data;
                }
            )
        if(localStorage.getItem('currentUser')){
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
            this.userData = JSON.parse(localStorage.getItem('currentUser'));
            this.userEmail = this.userData['email'];
            if(this.userData['roles'].includes("ROLE_MANAGER")){
                this.userManager = true;
              }
        }
        
        this.authService.obconnect.subscribe((idMessage) =>{ 
            this.authService.getUserInvitation(0, 10).subscribe(res => {
              this.inviteBadge = res['hydra:totalItems'];
            });
            console.log("connecteddd");
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
            this.userData = JSON.parse(localStorage.getItem('currentUser'));
        });

        
        this.authService.photoHeader.subscribe((term) =>{ 
          if(term){
            //console.log("ress "+term)
            this.userData.imageUrl = term;
            this.getAvatarlUrl(term);
          }
          //console.log('dans le smarnumber idmessage ==> '+idMessage);
        });
        
        
    }

    goToDashboard(){
      this.router.navigate(['/dashboard']);
    }

    changeLang() {
        this.translate.use(this.lang);
        this.messageService.setTranslate(this.lang);
    }
    useLanguage(language: string) {
        this.translate.use(language);
        this.messageService.setTranslate(language);
    }

    logout() {
        //console.log('logging out');
        this.authService.logout();
    }

    selectProject(){
        this.authService.selectProject.emit("select");
    }

    openParams(){
        this.authService.selectProject.emit("params");
    }

    public openSidebar() {
        this.hideNav = this.hideNav == 0 ? 1 : 0;
        this.coreService.toggleSidebar.emit(this.hideNav);
        this.isHamburguer = !this.isHamburguer;
    }
    public openNavbarmobile() {
        this.isOpenMenu = !this.isOpenMenu;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogVideo, {
          width: '700px',
          height: '450px',
          data: {}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          //console.log('The dialog was closed');
        });
    }

    getAvatarlUrl(avatarLink){
        return environment.server_url+avatarLink;
      }

    
      openProfilDialog() {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'contactpanel';
        dialogConfig.id = 'balchal718';
        dialogConfig.width = '550px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
        const dialogRef = this.dialog.open(ProfileComponent, dialogConfig);
    
        dialogRef.afterClosed().subscribe((result: any) => {
        });
    
      }

      showLanguage(){
        this.authService.selectProject.emit("langue");
      }

      showInvitation() {
        this.dialog.closeAll();
        const dialogRef = this.dialog.open(InvitationDialogComponent, {
          width: '650px',
          data: {},
          autoFocus: false
        });
    
        dialogRef.afterClosed().subscribe(result => {
          //console.log('The dialog was closed');
          if (result) {
            //console.log('/single/'+result.project.id);
            this.router.navigate(['/project/' + result.project.id+'/openmsg']);
            //this.authService.obRefreshProjet.next(result.project.id);
            console.log("dash-menu "+JSON.stringify(result.project))
            this.authService.obProjetAdded.next(result.project);
            this.authService.getUserInvitation(0, 10).subscribe(res => {
              this.authService.obInviteBadge.next(res);
            });
            this.messageService.messageUpdated();
          }
          /*
          this.invitationicon = false;
          if(window.location.href.endsWith("/dashboard")){
            this.filicon = true;
          } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
            this.parametreicon = true;
          } else if(window.location.href.endsWith("/profile")){
            this.profilicon = true;
          }*/
    
        });
      }

      openDialogContacts() {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'contactpanel';
        dialogConfig.id = 'balchal717';
        dialogConfig.width = '550px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
        const dialogRef = this.dialog.open(DashboardDialogComponent, dialogConfig);
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.profile = result;
            this.router.navigate(['/profile/' + result]);
          }
        });
    
      }
    
}
