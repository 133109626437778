import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from '../message/message.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private apiKey = 'AIzaSyCv877kzENL0PcuXCvcn4PxxKw85IurUuQ'; // Remplacez par votre clé API
  private apiUrl = 'https://translation.googleapis.com/language/translate/v2?key='+this.apiKey;
  private apiUrlDetect = 'https://translation.googleapis.com/language/translate/v2/detect?key='+this.apiKey;
  lang = "en";

  constructor(private http: HttpClient, private messageService: MessageService) { 
    /*this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });*/
  }

  // Fonction pour traduire un texte
  translateText(text: string, targetLanguage = "en"): Observable<any> {
    const body = {
      q: text,
      target: targetLanguage,
      format: 'text',
      key: this.apiKey
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.apiUrl, body, { headers });
  }


  detectLanguage(text: string): Observable<any> {
    const body = {
      q: text
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(this.apiUrlDetect, body, { headers });
  }
}

