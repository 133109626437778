import { Component, OnInit, Pipe, PipeTransform, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Lightbox } from 'ngx-lightbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog, MatTable } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import {environment} from 'src/environments/environment';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

@Component({
  selector: 'app-ged',
  templateUrl: './ged.component.html',
  styleUrls: ['./ged.component.scss']
})
export class GedComponent implements OnInit {

  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild('targetGED', {static: true}) targetGED: ElementRef;

  perPage = 30;
  resultsLength = 0;
  files = null;
  chosenFilter = null;
  type = "face";
  projectId = null;
  expanded:boolean = false;
  @Input() modeProjet: any;
  drive = false;
  filesadded: any = [];
  newTag = "";
  showpoup: boolean = false;

  constructor(private authService: AuthService,private _lightbox: Lightbox, private dialog: MatDialog) { 
  }

  ngOnInit() {
    //this.paginator.pageIndex = 0;
  }

  filterFichier(chosenFilter, ID, scroll){
    //console.log({chosenFilter})
    this.chosenFilter = chosenFilter;
    this.projectId = ID;
    this.resultsLength = 0;
    this.files = null;
    this.paginator.pageIndex = 0;
    if(chosenFilter.smartNumber == "instant"){
      this.fetchFilesInstant(0, scroll);
      this.type = "instant";
    } else if(chosenFilter.smartNumber == "projectinstant"){
      this.fetchFilesInstantProject(0, scroll);
      this.type = "instant";
    } else {
      this.type = "face";
      this.fetchFiles(0, scroll);
    }
  }

  closeBloc(){
    this.authService.closeBloc.emit("1");
  }

  reset(){
    this.chosenFilter = null;
    this.projectId = null;
    this.resultsLength = 0;
    this.files = null;
    this.paginator.pageIndex = 0;
    this.expanded = false;
  }

  onSelectResponse(event){
    event.addedFiles.map((item)=>{
      this.filesadded.push(item);
      //this.addFile();
    })   
  }

  addFile() {
    var projectid = '/api/projects/' + this.projectId;
    var newTag = (<HTMLInputElement>document.getElementById("newTag")).value;
    if(newTag.trim() == "" || newTag.trim().length == 0){
      alert("Veuillez entrer un tag");
      return;
    }

    if(this.filesadded.length == 0){
      alert("Veuillez sélectionner les fichiers");
      return;
    }
    this.authService.addFile(newTag, projectid, this.filesadded).subscribe(res => {
      this.fetchFiles(0, 0);
      /*
      console.log('reponse envoi message: ',res["filegeneric"]);
      this.files = this.files.concat(res["filegeneric"]);
      this.files.sort((a, b) => {
          if ( a.createdAt < a.createdAt ){
            return -1;
          }
          if ( a.createdAt > b.createdAt ){
            return 1;
          }
          return 0;
        })*/
      this.filesadded = [];
    },error => {
      alert("oups une erreur s'est produite!");
    });
    /*
    this.authService.addFile(this.filesadded, this.projectId).subscribe(res => {
      console.log('reponse envoi message: ',res);
      this.files.push(res);
      console.log("added");
    },error => {
      alert("oups une erreur s'est produite!");
    });*/
  }

  onRemoveAnswer(event) {
    //console.log(event);
    this.filesadded.splice(this.files.indexOf(event), 1);
  }

  onRemoveFile(event) {
    //console.log(event);
    this.filesadded.splice(this.files.indexOf(event), 1);
  }

  openpopup(){
    this.showpoup = !this.showpoup;
    (<HTMLInputElement>document.getElementById("newTag")).value = "";
  }

  fetchFiles(page, scroll){
    this.expanded = true;
    this.showpoup = false;
    this.drive = this.chosenFilter.drive;
    if(this.chosenFilter.type == "http") {
      this.files = [];
      return;
    }
    this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, false).subscribe(res => {
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
    })
    if(this.drive){
      this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, true).subscribe(res => {
        this.resultsLength = this.resultsLength + res['hydra:totalItems']; 
        this.files = this.files.concat(res["hydra:member"]);
        this.files.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
      })
    }
    if(scroll)
          this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
    /*
    this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, true).subscribe(res => {
      //console.log({res})
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
      if(scroll)
        this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
      //console.log(res);
    })
    */
  }

  downloadFile(media: any): void {
    const encodedFileName = encodeURIComponent(media.originalName);
    //return `path/to/your/files/${media.path}?filename=${encodedFileName}`;
    const link = document.createElement('a');
    console.log("name : "+this.getMediaLink(media)+'?filename='+encodedFileName)
    link.href = this.getMediaLink(media)+'?filename='+encodedFileName;
    link.download = 'simplefile.pdf';
    link.click();
  }

  fetchFilesInstantProject(page, scroll){
    this.expanded = true;
    this.showpoup = false;
    console.log("fetchFilesInstantProject : "+JSON.stringify(this.chosenFilter))
    this.authService.getFilesInstantProject(this.projectId, page, this.perPage, null, this.chosenFilter, false).subscribe(res => {
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
    })
    if(scroll)
          this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
  }

  fetchFilesInstant(page, scroll){
    this.expanded = true;
    this.showpoup = false;
    this.drive = this.chosenFilter.drive;
    console.log("chosen : "+JSON.stringify(this.chosenFilter))
    this.authService.getFilesInstant(this.projectId, page, this.perPage, null, this.chosenFilter, false).subscribe(res => {
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
    })
    if(this.drive){
      this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, true).subscribe(res => {
        this.resultsLength = this.resultsLength + res['hydra:totalItems']; 
        this.files = this.files.concat(res["hydra:member"]);
        this.files.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
      })
    }
    if(scroll)
          this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
    /*
    this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, true).subscribe(res => {
      //console.log({res})
      this.resultsLength = res['hydra:totalItems']; 
      this.files = res["hydra:member"];
      if(scroll)
        this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
      //console.log(res);
    })
    */
  }

  getMediaLink(media){
    console.log("med : "+media.originalName)
    if(this.type == "instant"){
      return this.authService.getMediaDataInstant(media.contentUrl);
    } else return this.authService.getMediaData(media.contentUrl);
  }

  open(url){
    

    url = this.getMediaLink(url);
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);

  }

  handlePage(e: any) {
    if(this.type == "instant"){
      this.fetchFilesInstant(e.pageIndex, true);
    } else this.fetchFiles(e.pageIndex, true);
  }
  

  getIcon(extension, extensionfile){
    let filelink = "";
    if(extensionfile){
    if(extensionfile.startsWith('xl') || (extensionfile == 'csv' && !extension.includes("text/plain"))){
      filelink = "../../assets/icons/doc-excel.png";
    }else if(extensionfile.startsWith('ppt')){
      filelink = "../../assets/icons/doc-ppt.png";
    }else if((extensionfile.startsWith('do') || extensionfile == 'odt')){
      filelink = "../../assets/icons/doc-word.png";
    }else if(extensionfile.includes("zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extensionfile.includes("pdf")){
      filelink = "../../assets/icons/doc-pdf.png";
    }else if(extension.includes("text/plain")){
      filelink = "../../assets/img/icone-inconnu.png";
    }}

    return filelink ? filelink : false;
  }

  /*getIcon(extension){
    let filelink = "";
    if(extension.includes("spreadsheetml.sheet") || extension.includes("application/vnd.ms-excel")
    || extension.includes("application/octet-stream") || (extension.includes("text/plain"))){
      filelink = "../../assets/icons/excel.png";
    }else if(extension.includes("presentationml.presentation")){
      filelink = "../../assets/icons/ppt.png";
    }else if(extension.includes("application/msword") || extension.includes("wordprocessingml.document")){
      filelink = "../../assets/icons/word.png";
    }else if(extension == 'application/pdf'){
      filelink = "../../assets/icons/pdf.png";
    }else if(extension.includes("application/zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extension.includes("video/quicktime")){
      filelink = "../../assets/icons/video.png";
    }

    return filelink ? filelink : false;
  }*/

  openModal(media){

    const dialogRef = this.dialog.open(scrollPjDialog, {
      width: '90%',
      height: '90%',
      data:{files: this.files, currentFile: media}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result => '+ result);
      if (result) {

      }

    });

  }

}

@Pipe({
  name: 'truncate'
 })
 export class TruncatePipe implements PipeTransform {
 transform(value: string, args: string[]): string {
     const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
     const trail = args.length > 1 ? args[1] : '...';
     return value.length > limit ? value.substring(0, limit) + trail : value;
    }
 }



@Component({
  templateUrl: './scrollPjDialog.html',
  styleUrls: ['./scrollPjDialog.scss']
})
export class scrollPjDialog {  

  docsUrl = environment.server_url;
  seletedMedia: any;
  currentIndex = 0;
  fileList: any;
  currentMedia: any;

  constructor(
    public dialogRef: MatDialogRef<scrollPjDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    protected _sanitizer: DomSanitizer) {
      //console.log("jjjjjjjj");

    //console.log({medialiste: this.data});

    //this.fileList = this.data.files;
    this.currentMedia = this.data.currentFile;
    this.seletedMedia = this.currentMedia;
      /*
    for (var i = 0; i < this.fileList.length; i++) {
      if(this.fileList[i] == this.currentMedia){
        this.currentIndex = i;
      }
    }

    this.seletedMedia = this.fileList[this.currentIndex];*/

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  validate(): void {
    this.dialogRef.close();
  }

  onClickPrevious(){
    if ((this.currentIndex-1) >= 0) {
      this.currentIndex -= 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClickNext(){
    if ((this.currentIndex+1) < this.fileList.length) {
      this.currentIndex += 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }
 
  getPdfLink(mediaUrl){
    console.log("pdf link")
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.getMediaLink(mediaUrl));
  }

  getMediaLink(media){
    //console.log(JSON.stringify(media))
    console.log("reccc "+media.message.type)
    if(media.message.recipient || media.message.type == "COMMENT"){
      console.log("url : "+this.authService.getMediaData(media.contentUrl))
      return this.authService.getMediaData(media.contentUrl);
    } else return this.authService.getMediaDataInstant(media.contentUrl);
    /*
    if(!(media.message.recipient) && media.message.type == "MessageInstant"){
      return this.authService.getMediaDataInstant(media.contentUrl);
    } else return this.authService.getMediaData(media.contentUrl);
    */
    //return this.authService.getMediaData(mediaUrl);
  }

  getMediaExtension(media){

    if(media.extensionfile == 'pdf' || media.extensionfile.startsWith('ppt') || media.extensionfile.startsWith('xl') || (media.extensionfile == 'csv') || media.extensionfile.startsWith('do') || media.extensionfile == 'odt' || media.extension == 'application/octet-stream' || media.extensionfile == 'zip'){
      return 1;
    }
    else if (media.extensionfile=='ico' || media.extension.startsWith('image')){
      return 2;
    }
    else if(media.extension.startsWith('audio')){
      return 3;
    }
    else if(media.extension.startsWith('video')){
      return 4;
    }
    else
    {
      return 5;
    }

  }

  getIcon(extension){
    let filelink = "";
    if(extension.includes("spreadsheetml.sheet") || extension.includes("application/vnd.ms-excel")
    || extension.includes("application/octet-stream")){
      filelink = "../../assets/icons/excel.png";
    }else if(extension.includes("presentationml.presentation")){
      filelink = "../../assets/icons/ppt.png";
    }else if(extension.includes("application/msword") || extension.includes("wordprocessingml.document")){
      filelink = "../../assets/icons/word.png";
    }else if(extension == 'application/pdf'){
      filelink = "../../assets/icons/pdf.png";
    }else if(extension.includes("application/zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extension.includes("video/quicktime")){
      filelink = "../../assets/icons/video.png";
    }

    return filelink ? filelink : false;
  }

}



 